import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import styled from 'styled-components';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import storage, { removeSession } from '../../../../lib/storage';
import { authenticationGoogleRecaptchaAPI, login, loginDemo } from '../../../../lib/api';
import { useSnackBar } from '../../../../contexts/snackbar';
import PhoneComponent from './PhoneComponent.jsx';
import BoxContainer from '../box-container';
import { TextLabel } from '../labels/label-access';
import { SubmitButton } from '../buttons';
import DialogComponent from '../../../watch/components/DialogComponent';
import { useMediaQuery } from 'react-responsive';
import { PatientContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';
import AcceptTermsOfUse from '../accept-terms-of-use';
import { useMixpanel } from 'react-mixpanel-browser';
import { setGlobalPhone } from '../../../../utils/functions';
import VlabIcon from '../../../../components/Icons/vlab';

const InputAccessCode = styled.input`
  box-sizing: border-box;
  display: flex;
  font-style: normal;
  width: 100%;
  height: 42px;
  border: 1px solid #DDE5EC;
  border-radius: 8px;
  margin-bottom: 10px;
  justify-content: center;
  padding-left: 6px;
  letter-spacing: 1px;
  transition: border-color 0.3s ease;
  color: #020523;
  &:placeholder {
    color: #020523;
  }
  &:hover {
    border: 1px solid #B7C6D1;
  }
  &:hover::placeholder {
    color: #8B98A3;
  }
  &:active {
    border: 1px solid #B7C6D1;
  }
  &:focus {
    outline: none;
    border: 1px solid #8B98A3;
    color: #020523;
  }
  &:not(:hover)::placeholder {
    color: #DDE5EC;
  }
`;

const PoweredBy = styled.img`
  width: 110px;
  height: 20px;
  object-fit: contain;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const LabelAccess = styled.p`
  font-style: normal;
  align-self: flex-start;
  font-weight: 400;
  font-size: 13px;
  display: flex;
  letter-spacing: 0.5px;
  color: #0166FF;
  justify-content: right;
  margin: 4px 0 12px;
  cursor: pointer;
`;

const Flexform = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 24px;
  width: 295px;
  justify-content: flex-start;
  @media screen and (max-width: 486px) {
    width: 295px;
    margin: 24px 24px;
  } 
`;

const ContainerButton = styled.div`
  display: flex;
  margin-top: 18px;
  align-items: center;
  justify-content: space-between;
`;

const FlagIcon = styled.div`
  height: 24px;
  width: 32px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  
  border-radius: 2px;
`;

const ComponentLogin = () => {

  const { settings } = useContext(PatientContext)
  const { app, loginButton, country } = settings
  const captchaRef = useRef(null)
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()
  const [attempts, setAttempts] = useState(0)
  const [showCaptcha, setShowCaptcha] = useState(false)
  const [token, setToken] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [showAppBanner, setShowAppBanner] = useState(false);
  const [acceptPrivacyTerms, setAcceptPrivacyTerms] = useState(false);
  const [acceptDataTerms, setAcceptDataTerms] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1023px)' });
  const { id } = useParams();
  const { t } = useTranslation()
  const mixpanel = useMixpanel();

  useEffect(() => {
    removeSession()
  },[])

  const loginSuccess = (token) => {

    storage.saveSession(token)
    if(id === 'lp-demo') {
      navigate('/loading/lp-demo')
      return
    }
    navigate('/loading')
  }

  const loginForgot = () => {
    navigate('/forgotten')
  }

  const handleError = async (error) => {

    if(window.location.hostname.includes('vlab.live')) {

      if(attempts === 2) {
        
        setShowCaptcha(true)
      } else {
        
        setAttempts(prevState => prevState + 1)
      }
    }
    showSnackBar(t('alertMessages.errorData', { error: error !== '' ? error + '.' : error }), 'error');
  }

  const handleSubmit = async (values) => {
    setIsLoading(true)    
    try {

        const { data } = id === 'lp-demo' ? 
        await loginDemo({
          'phone': values.phone,
          'accessCode': values.accessCode,
          'acceptPrivacyTerms': values.acceptPrivacyTerms,
          'acceptDataTerms': values.acceptDataTerms,
        })
        : await login({
          'phone': values.phone, 
          'accessCode': values.accessCode, 
          'acceptPrivacyTerms': values.acceptPrivacyTerms,
          'acceptDataTerms': values.acceptDataTerms,
        })

        window.dataLayer.push({
          event: 'logged-in',
          identifier: values.phone
        });

        mixpanel.track('WEBAPP_LOGGED_IN', { 
          distinct_id: values.phone,
        });
        
        mixpanel.track('ACCEPT_TERMS_OF_USE', { 
          distinct_id: values.phone,
          'ACCESS_CODE': values.accessCode,
          'ACCEPT_PRIVACY_TERMS': true,
          'ACCEPT_DATA_SHARING_TERMS': true,
        });

        setGlobalPhone(values.phone)
        loginSuccess(data)

    } catch (error) {
      if (error.response.data.error) {
        handleError(error.response.data.error)
      } else {
        handleError('')
      }

    } finally {
      setIsLoading(false)    
    }
  }

  const handleChangeCaptcha = async (value) => {
    
    if(value) {

      const { data } = await authenticationGoogleRecaptchaAPI(value)
      
      const { success } = JSON.parse(atob(data))

      if(success) {
        
        setShowCaptcha(false)
        setAttempts(0)
      } else {

        captchaRef.current.reset()
      }
    }
  } 

  const validadeLogin = Yup.object().shape({
    phone: Yup.string()
    .required(),
    accessCode: Yup.string()
    .required('Código de acesso é obrigatório!')
    .matches(/^[0-9]+$/),  
  })

  return (
      <Formik
        initialValues={{ phone: phoneNumber, accessCode: accessCode, acceptPrivacyTerms: acceptPrivacyTerms, acceptDataTerms: acceptDataTerms}}
        onSubmit={handleSubmit}
        validationSchema={validadeLogin}           
      >
        {(props) => (
        <BoxContainer>
            <Flexform>
            {showAppBanner && (isMobile || isTablet) && <DialogComponent open={true} type="app" />}
            <TextLabel>{t('login.phone')}</TextLabel>
            <PhoneComponent
              inputProps={{ name: 'phone' }}
              onChange={props.handleChange('phone')}
              country={country ? country.toLowerCase() : 'br'}
            />

            <TextLabel>{t('login.accessCode')}</TextLabel>
            <InputAccessCode
              name="accessCode"
              type="tel"
              onChange={props.handleChange('accessCode')}
            />

            <LabelAccess onClick={loginForgot}>
            {t('login.forgotAcess')}
            </LabelAccess>

            {showCaptcha && <ReCAPTCHA
              style={{ marginTop: 10 }}
              ref={captchaRef}
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={handleChangeCaptcha}
            />}

            <div style={{ width: '100%',
                          display: 'flex', 
                          flexDirection: 'column', 
                          alignItems: 'flex-start', 
                          gap: '2px', 
                        }}>
                  <AcceptTermsOfUse 
                    label={t('acceptTerms.privacyPolitics')} 
                    onAcceptChange={(accepted) => {
                      setAcceptPrivacyTerms(accepted);
                      props.setFieldValue('acceptPrivacyTerms', accepted);
                    }} 
                  />
                  <AcceptTermsOfUse
                    label={t('acceptTerms.healthPolitics')} 
                    onAcceptChange={(accepted) => {
                      setAcceptDataTerms(accepted);
                      props.setFieldValue('acceptDataTerms', accepted); 
                    }} 
                  />
            </div>
            
            <ContainerButton>
              <SubmitButton 
                type="submit" 
                color={loginButton ? loginButton.backgroundColor : "#0166FF"}
                fontColor={loginButton ? loginButton.textColor : "#fff"}
                disabled={showCaptcha || !acceptPrivacyTerms || !acceptDataTerms}
              >
              {t('buttons.enter')}
              </SubmitButton>
            </ContainerButton>

            {/* <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', marginTop: '14px', alignItems: 'center', gap: 10 }}>
              <FlagIcon
                style={{ backgroundImage: `url(${ar_icon})` }}
                onClick={() => { i18n.changeLanguage('es') }}
                title={t('buttons.selectFlag')}
              />
              <FlagIcon
                style={{ backgroundImage: `url(${br_icon})` }}
                onClick={() => { i18n.changeLanguage('pt-BR') }}
                title={t('buttons.selectFlag')}
              />
              <FlagIcon
                style={{ backgroundImage: `url(${us_icon})` }}
                onClick={() => { i18n.changeLanguage('en-US') }}
                title={t('buttons.selectFlag')}
              />
            </div> */}

            <div style={{marginTop: '32px'}}>
              <a href='https://www.vlabhealth.com/v-baby' target='_blank' style={{ textDecoration: 'none', font:'inherit'  }}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h3 style={{ marginRight: 6 }}>{t('poweredBy.title')}</h3> <VlabIcon width={56} />
                </div>
              </a>
            </div>

          </Flexform>
         </BoxContainer>
       )}
      </Formik>
  );
};

export default ComponentLogin;