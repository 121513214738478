import React from 'react'

import { BrowserRouter, HashRouter, Routes, Route } from 'react-router-dom'
import {
    Watch,
    ShareExam,
    Login,
    RequestPermissions,
    PaymentOpened,
    RecoveryAccess,
    EmbedPlayer,
    NotFound,
    LoadingPage,
    LivePage
} from './pages'
import WatchDemo from './pages/demo';

const AppRoutes = () => {
    const useHash = window.location.hash.includes('#/');
    const isVbabyApp = window.location.hostname.includes('vbaby.app')
    const isException = window.location.hostname.match('assista.urpinheiros.com.br')
    // Eu sei que isso é feio, mas precisa ser rápido só para esse cliente, por enquanto :c
    if((useHash || !isVbabyApp) && !isException) {
        return (
            <HashRouter>
                 <Routes>
                    <Route path='/share-exam/:exam/:hash/:intern?' element={<ShareExam />} />
                    <Route path='/embed-player/:exam/:hash' element={<EmbedPlayer />} />
                    <Route path='/loading/:id?' Component={LoadingPage} />
                    <Route path='/watch' Component={Watch} />
                    <Route path='/:id?' Component={Login} />
                    <Route path='/forgotten' Component={RecoveryAccess} />
                    <Route path='/request-permissions' Component={RequestPermissions} />
                    <Route path='/PaymentOpened' Component={PaymentOpened} />
                    <Route path='/watch-demo' Component={WatchDemo} />
                    <Route path='*' Component={NotFound} />
                </Routes>
            </HashRouter>
        )
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/:id?' Component={Login} />
                <Route path='/loading/:id?' Component={LoadingPage} />
                <Route path='/live' Component={LivePage} />
                <Route path='/forgotten' Component={RecoveryAccess} />
                <Route path='/watch' Component={Watch} />
                <Route path='/request-permissions' Component={RequestPermissions} />
                <Route path='/PaymentOpened' Component={PaymentOpened} />
                <Route path='/watch-demo' Component={WatchDemo} />
                <Route path='*' Component={NotFound} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes  
